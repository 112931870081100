import React, { useState, useEffect, useMemo,  } from 'react';
import { Space } from 'antd'
import { DefaultLabel } from '@components/form/Defaultlabel'
import { DefaultForm } from '@components/form/DefaultForm'
import { Flex } from '@components/flex/flex';
import {  Controller,useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { DropdownForm } from '@components/form/Dropdown'
import firebase from 'firebase';
import  { NumberFormatValues } from 'react-number-format';
import InputNumberForm from '@components/form/NumberForm';


export const RegisterForm = React.forwardRef((props) => {
	const { control, errors} = useFormContext();
	const [isLoad, setIsLoad] = useState(false);
	const [province, setProvince] = useState([]);


	useEffect(() => {
		if (!isLoad) {
			const ref = firebase.database().ref('province/').orderByChild('label');
			ref.once('value').then((snapshot) => {
				setProvince(snapshot.val());
			});
			setIsLoad(true);
		}
	});


	const onPhone = (formatedvalues: NumberFormatValues) => {
    const { floatValue } = formatedvalues;
    if (floatValue) {
      return floatValue >= 0 && floatValue < 10000000000;
    }
    return true;
  };

	return (

		<Space direction="vertical" size={0}>
				<StyledFlex className={StyledFlex} flexWrap={'wrap'} justifyContent={'space-between'} columnGap={'10px'}>
					<SyledDefaultLabel>
						<p>ชื่อ</p>
						<Controller
							name={'name'}
							control={control}
							defaultValue={null}
							render={({ value, onChange, name }) => {
								return (<DefaultForm
									value={value}
									name={name}
									error={errors['name']}
									placeholder={'กรุณากรอกชื่อ'}
									onChange={onChange}
								/>)
							}}
						/>
					</SyledDefaultLabel>

					<SyledDefaultLabel>
						<p>นามสกุล</p>
						<Controller
							name={'surname'}
							control={control}
							defaultValue={null}
							render={({ value, onChange, name }) => {
								return (<DefaultForm
									value={value}
									name={name}
									error={errors['surname']}
									placeholder={'กรุณากรอกนามสกุล'}
									onChange={onChange}
								/>)
							}}
						/>

					</SyledDefaultLabel>

				</StyledFlex>

				<StyledFlex flexWrap={'wrap'} justifyContent={'space-between'} columnGap={'10px'}>
					<SyledDefaultLabel>
						<p>อีเมล</p>
						<Controller
							name={'Email'}
							control={control}
							defaultValue={null}
							render={({ value, onChange, name }) => {
								return (<DefaultForm
									value={value}
									name={name}
									error={errors['Email']}
									placeholder={'กรุณากรอกอีเมล'}
									onChange={onChange}
								/>)
							}}
						/>

					</SyledDefaultLabel>

					<SyledDefaultLabel>
						<p>เบอร์โทรศัพท์</p>
						<Controller
							name={'Mobilephone_No'}
							control={control}
							defaultValue={null}
							render={({ value, onChange, name }) => {
								return (<InputNumberForm
									value={value}
									name={name}
									error={errors['Mobilephone_No']}
									placeholder={'กรุณากรอกหมายเลขโทรศัพท์'}
									onChange={onChange}
									isAllowed={(formatedvalues) => onPhone(formatedvalues)}
									allowLeadingZeros={true}
									decimalScale={0}
								/>)
							}}
						/>

					</SyledDefaultLabel>

				</StyledFlex>
				<StyledFlex isFlex={false} flexWrap={'wrap'} justifyContent={'space-between'} columnGap={'10px'}>
					<SyledDefaultLabel>
						<p>จังหวัด</p>
						<Controller
						name={'province'}
						control={control}
						defaultValue={null}
						render={({ value, onChange, name }) => {
							return (	
							<DropdownForm
							value={value}
								onChange={onChange}
								placeholder={'กรุณาเลือกจังหวัด'}
								passedOptions={province}
							/>)
						}}
						/>
					
					</SyledDefaultLabel>
				</StyledFlex>

				<StyledFlex flexWrap={'wrap'} justifyContent={'space-between'} columnGap={'10px'}>
					<SyledDefaultLabel>
						<p>เว็ปไซต์ผลงาน</p>
						<Controller
							name={'Profile_URL'}
							control={control}
							defaultValue={null}
							render={({ value, onChange, name }) => {
								return (<DefaultForm
								value={value}
									name={name}
									error={errors['Profile_URL']}
									placeholder={'กรอกเว็ปไซต์'}
									onChange={onChange}
								/>)
							}}
						/>

					</SyledDefaultLabel>
				</StyledFlex>
		</Space>

	);
}
)


export const SyledDefaultLabel = styled(DefaultLabel)`
	padding-right: 10px;
	p{
		margin-bottom:2px
	}
	.ant-select-selection-placeholder{
		font-size: 14px;
	}
	.ant-select-selection-item{
		font-size: 14px;
	}

`
const StyledFlex = styled(Flex)`
@media (max-width: 1200px) {
	justify-content:center !important;
}
`