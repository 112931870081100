import React from 'react';
import * as antd from 'antd';

export interface FlexProps {
	children?: React.ReactNode;
	className?: string;
	isFlex?: boolean;
	isInline?: boolean;
	/****** Container Props ********/
	flexDirection?: 'row' | 'column';
	justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'initial' | 'inherit';
	flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
	alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
	/****** Child Props ********/
	flexGrow?: number;
	flexShrink?: number;
	flexBasis?: number | string;
	flex?: string;
	/****** Common Layout Props ********/
	padding?: string;
	margin?: string;
	width?: string;
	height?: string;
	maxWidth?: string;
	maxHeight?: string;
	/****** Gap Props ********/
	rowGap?: string;
	columnGap?: string;

	/****** Other option ********/
	style?: React.CSSProperties;
}
export const Flex: React.FC<FlexProps> = ({
	isFlex,
	flexDirection,
	flexGrow,
	isInline,
	flexBasis,
	flexShrink,
	className,
	justifyContent,
	flexWrap,
	flex,
	alignItems,
	margin,
	padding,
	width,
	height,
	maxWidth,
	rowGap,
	columnGap,
	style,
	children,
	...props }) => {
	return (
		<div
			className={className}
			style={{
				display: 'flex',
				justifyContent: justifyContent || 'flex-start',
				flexDirection: flexDirection || 'row',
				flexGrow: flexGrow || 0,
				flexBasis: flexBasis || 'auto',
				flexShrink: flexShrink || 1,
				flexWrap: flexWrap || 'nowrap',
				flex: flex || '0 1 auto',
				alignItems: alignItems || 'stretch',
				margin: margin || '0',
				padding: padding || '0',
				width: width || 'auto',
				height: height || 'auto',
				maxWidth: maxWidth || 'none',
				rowGap: rowGap || undefined,
				columnGap: columnGap || undefined,
				...(style || {}),
			}}
			{...props}
		>
			{children}
		</div>
	)
}