import React, { useState } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';

export const RegisterLayout: React.FC = (props) => {
	const [collapse, setCollapse] = useState(false);
	const siderWidth = 285;
	return (
		<StyleRegisterComp>
			{/* <Layout> */}
			{/* <Sider
					width={siderWidth}
					breakpoint="lg"
					collapsedWidth="0"
					// onBreakpoint={broken => {
					//   setBreakPoint(broken);
					// }}
					onCollapse={(collapsed) => {
						setCollapse(collapsed);
					}}>
						<div>
						Test menu
						</div>

				</Sider> */}
			{/* <Layout className="site-layout" style={{ marginLeft: collapse ? 36 : siderWidth }}> */}
			{/* <Content> */}
			<StyleCol {...props}>
				{props.children}
			</StyleCol>
			{/* </Content> */}
			{/* </Layout> */}
			{/* </Layout> */}

		</StyleRegisterComp>
	)
}

export const StyleCol = styled.div`
	width: 55%;
	padding-bottom:20px;
	top: 124px;
	border-radius: 4px;
	margin-bottom:-50px;
	@media (max-width: 1100px) {
		justify-content:center !important;
}
@media (max-width: 700px) {
	width: 100%;
	padding:10px;
}
`
const StyleRegisterComp = styled.div`
	margin-top:50px;
	display:flex;
	justify-content:center;
	@media (max-width: 700px) {
		margin-top:0px;
	}
	
`
const Content = styled(Layout.Content)`
  width: 100%;
  padding: 20px 0 0;
  overflow: initial;
`;
const Sider = styled(Layout.Sider)`
  height: 100vh;
  position: fixed;
	left: 0;
	background:white;
	color:yellow;
  .ant-layout-sider-zero-width-trigger {
    top: 10px;
    font-size: 20px;
    line-height: 47px;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
    border-radius: 0 5px 5px 0;
  }
  .ant-layout-sider-zero-width-trigger {
    shadow-box: none;
  }
`;