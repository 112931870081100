import React, { useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import { RefSelectProps, SelectProps } from 'antd/lib/select';
import { DeepMap, FieldError, FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { fromEvent, Subscription } from 'rxjs';

export interface DropdownFormPassedOption {
	key: string;
	value: string | number;
	label: string;
}

type DropdownFormProps = SelectProps<any> & { passedOptions: DropdownFormPassedOption[] } & {
	error?: DeepMap<FieldValues, FieldError>;
	touched?: FieldNamesMarkedBoolean<FieldValues> | boolean;
	required?: boolean;
	type?: string;
	optionStyle?: React.CSSProperties;
};

const DropdownForm = React.forwardRef<RefSelectProps, DropdownFormProps>(
	({ passedOptions, error, touched, required, ...props }, ref) => {
		// const ref = useRef<any>(null);
		const [open, setOpen] = useState<boolean>(false);
		const className = useMemo(() => {
			const classNames: string[] = [];
			if (error) {
				classNames.push('error');
			}
			if (touched) {
				classNames.push('touched');
			}
			if (required) {
				classNames.push('required');
			}
			return [...classNames, props.className || ''].join(' ');
		}, [error, touched, required, props.className]);
		useEffect(() => {
			let sub: Subscription;
			if (open) {
				sub = fromEvent<MouseEvent>(document, 'click').subscribe({
					next: () => {
						setOpen(false);
					},
				});
			}
			return () => {
				if (sub) {
					sub.unsubscribe();
				}
			};
		}, [open]);

		const onClick = () => {
			if (!open) {
				setOpen(true);
			}
		};
		return (
			<DropdownStyled
				{...props}
				ref={ref}
				className={className}
				dropdownClassName={'default-dropdown'}
				onClick={onClick}
				open={open}
			>
				{(passedOptions || []).map((option) => {
					return (
						<DropdownStyled.Option key={option.label} value={option.value} style={props.optionStyle}>
							{option.label}
						</DropdownStyled.Option>
					);
				})}
			</DropdownStyled>
		);
	}
);

DropdownForm.displayName = 'DropdownForm';
export { DropdownForm };

const DropdownStyled = styled(Select)`

  width: 303px;
  font-size: 14px;
  color: #757575;
  letter-spacing: 0;
  &.ant-select-disabled {
    .ant-select-selection-item {
      color: rgba(0, 0, 0, 0.25);
    }
  }
  &.error {
    .ant-select-selector {
    }
  }
  .ant-select-selector {
		height: 36px !important;
    border-radius: 4px !important;
  }
  .ant-select-selection-item {
    font-size: 20px;
    color: #757575;
    letter-spacing: 0;
  }
  .ant-select-selection-placeholder {
    font-size: 20px;
    line-height: 24px;
    color: #8a8a8a;
    letter-spacing: 0.25px;
    font-weight: normal;
    font-style: normal;
    opacity: 0.6;
  }
  .ant-select-arrow {
    svg {
      font-size: 10px;
      color: #000000;
      font-weight: bold;
    }
  }
`;

// font-size: 20px;
//     line-height: 24px;
//     color: #757575;
