import React from 'react';
import styled from 'styled-components';
import { Flex } from '@components/flex/flex';
export interface LavelProps {
	title: string;
	style?: React.CSSProperties;
}

export const HeaderLabel: React.FC = (props) => {
	return (
		<StyledHeader {...props}>
			{props.children}
		</StyledHeader>
	)
}
const StyledHeader = styled.p`
font-family: Prompt;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;`