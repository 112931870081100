import * as antd from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import React, { useMemo } from 'react';
import styled from 'styled-components';

export type DefaultButtonType = undefined | 'default' | 'primary' | 'secondary' | 'save';
export type DefaultButtonProps = ButtonProps &
	React.RefAttributes<HTMLElement> & {
		buttonType?: DefaultButtonType;
	};

export const DefaultButton: React.FC<DefaultButtonProps> = ({ className, ...props }) => {
	const classNames = className || '';
	return (
		<Button {...props} className={classNames}>
			{props.children}
		</Button>
	)
}

const Button = styled(antd.Button)`
background: #44567A;
height: 36px;
width: 150px;
left: 0px;
top: 0px;
border-radius: 4px;
.ant-btn:focus {
  outline: none;
  box-shadow: none;
}

&:not([disabled]){
	background-color: #44567A;
	border-color: #44567A;
	color:white;
	&:hover{
		color: #7a7a7a;
		background-color: #eeeeee;
		border-color: #44567A;
	}
&.secondary{
	background: #ffffff;
	color: #44567A;
	:not([disabled]){
		&:hover{
			color: #7a7a7a
			background-color: #eeeeee;
			border-color: red;
		},
		&:focus {
			color: #7a7a7a;
			border-color: #44567A;
		}
}
}
`