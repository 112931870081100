import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { mainRoutes, RouteType } from 'routes.config';
import { Login } from 'pages/Login/Login'
import { PageLayout } from '@layouts/PageLayout'
import {Register} from 'pages/Register/Register'

export const Pages: React.FC = () => {
	const [loading, setLoading] = useState<Boolean>(false)
	useEffect(() => {
		// Auth here

		// If not auth show
	})
	if (loading)
		return <> Not authorize</>
	return (
		<PageLayout>
			<Switch>
				{/* {mainRoutes.map(({ key, path, ...route }: RouteType) => {
					return <Route key={key} path={path} {...route}></Route>;
				})} */}
				<Route key={'register'} path={'/'} component={Register}></Route>
			</Switch>
		</PageLayout>

	);
}