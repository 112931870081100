import React from 'react';
import styled from 'styled-components';
import { Flex } from '@components/flex/flex';
export interface LavelProps {
	title: string;
	style?: React.CSSProperties;
}

export const DefaultLabel: React.FC = (props) => {
	return (
		<HeaderLabel {...props}>
			{props.children}
		</HeaderLabel>
	)
}
const HeaderLabel = styled.p`
font-family: Prompt;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;

`