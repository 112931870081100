import React, { useState, useEffect, useMemo } from 'react';
import { Flex } from '@components/flex/flex';
import { DefaultLabel } from '@components/form/Defaultlabel'
import { CheckboxForm } from '@components/form/CheckBoxForm'
import { useFormContext, Controller } from 'react-hook-form';
import styled from 'styled-components';
import firebase from 'firebase';
import { Space, Input } from 'antd';

export const Gear = React.forwardRef((props) => {
	const { getValues,setValue, watch, control ,errors} = useFormContext();
	const [Options, setOptions] = useState([]);
	const [CategoryType, setCategoryType] = useState([]);
	const [isLoad, setIsLoad] = useState(false);
	const [isOtherSelect, setIsOtherSelect] = useState<boolean>(false)
	const [isOtherTypesSelect, setIsOtherTypesSelect] = useState<boolean>(false)

	useEffect(() => {
		if (!isLoad) {
			firebase.database().ref('camera/').on('value', function (snapshot) {
				if (snapshot.val() != null) {
					setOptions(snapshot.val().filter((item: any) => item !== null));
				}
			});
			firebase.database().ref('categorytype/').on('value', function (snapshot) {
				if (snapshot.val() != null) {
					setCategoryType(snapshot.val().filter((item: any) => item !== null));
				}
			});
			setIsLoad(true);
		}
	});
	useEffect(() => {
		const listTools = getValues('tool');
		const listType =getValues('type');
		if (listTools && listTools.includes('Others')) {
			setIsOtherSelect(true)
		} else {
			setIsOtherSelect(false)
		}
		if (listType && listType.includes('Others')) {
			setIsOtherTypesSelect(true)
		} else {
			setIsOtherTypesSelect(false)
		}

	}, [watch('tool'),watch('type')]);

	return (
		<StyledSpace direction="vertical" size={20} align="center" >
			<StyledFlex className={StyledFlex} flexWrap={'wrap'} justifyContent={'center'} columnGap={'10px'}>
				<div >
					<SyledDefaultLabel >อุปกรณ์ที่ใช้ <Dimelabel>
						(เลือกได้มากกว่า 1 ข้อ)</Dimelabel>
					</SyledDefaultLabel>
					<Controller
						name={'tool'}
						control={control}
						defaultValue={null}
						key={'gear'}
						render={({ value, onChange, name }) => {
							return (<CheckboxForm
								controlError={errors}
								value={value}
								items={Options}
								name={name}
								otherName={'otherTools'}
								onOtherSelect={isOtherSelect}
								onChange={onChange}
							/>)
						}}
					/>
					<SyledDefaultLabel>ประเภทงานถ่ายภาพ <Dimelabel>
						(เลือกได้มากกว่า 1 ข้อ)</Dimelabel>
					</SyledDefaultLabel>
					<Controller
						name={'type'}
						control={control}
						defaultValue={null}
						key={'type'}
						render={({ value, onChange, name }) => {
							return (<CheckboxForm
								controlError={errors}
								value={value}
								items={CategoryType}
								name={name}
								otherName={'otherTypes'}
								onOtherSelect={isOtherTypesSelect}
								onChange={onChange}
							/>)
						}}
					/>
				</div>
			</StyledFlex>
		</StyledSpace>
	)
})
const SyledDefaultLabel = styled(DefaultLabel)`
	margin:10px 0 0 10px;
	p{
		margin-bottom:2px
	}
`
const Dimelabel = styled.span`
	font-size:10px;
	color:rgba(0, 0, 0, 0.3);
`
const StyledFlex = styled(Flex)`
@media (max-width: 700px) {
width:313px;

}
@media (max-width: 1200px) {
	justify-content:center !important;
}
`
const StyledSpace = styled(Space)`
width:650px;
padding:auto;
@media (max-width: 700px) {
	justify-content:center !important;
	width:330px !important;
	padding:0% !important;
}
@media (max-width: 1000px) {
	justify-content:center !important;
	width:auto;
	padding:10%;
}
`