import React from 'react';
import styled from 'styled-components';
import { DefaultButton } from '@components/button/DefaultButton'
import { ModalProps } from 'antd/lib/modal/Modal';
import * as ant from 'antd'

interface DefaultModal extends ModalProps {
	visible: boolean
}

export const DefaultModal: React.FC<DefaultModal> = (props) => {
	return (
		<StyledModal
			footer={
				<>
					<DefaultButton style={{ width: '120px' }} onClick={props.onCancel}>
						ปิด
				</DefaultButton>
				</>
			}
			maskClosable={false}
			destroyOnClose={true}
			{...props}>
			{props.children}
		</StyledModal>
	)
}



const StyledModal = styled(ant.Modal)`
border-radius:10px
`
