import { FooterLayout } from '@layouts/Footer/FooterLayout';
import { HeaderLayout } from '@layouts/Header/HeaderLayout';
import { Layout } from 'antd';
import React from 'react';
import styled from 'styled-components';

export interface DefaultLayoutProps {
	children?: React.ReactNode;
}

export const PageLayout: React.FC<DefaultLayoutProps> = (props) => {
	return (
		<Styledlayout>
			<Content>{props.children}</Content>
		</Styledlayout>
	);
};

const Content = styled(Layout.Content)`
	z-index: -1;
	position:relative;
  margin-top: 64px;
	margin-bottom: 75px;
	@media (max-width: 700px) {
		margin-top: 0px !important;
	}
	
`;

const Styledlayout = styled(Layout)`
	overflow-y: scroll;
	display:flex;
	z-index: 0;
	position:relative;
	background: #E5E5E5
`
