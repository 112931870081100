import React from 'react'
import styled from 'styled-components'
import {Input} from 'antd';
import {InputProps as Props} from 'antd/lib/input'
import { DeepMap, FieldError, FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';

export type InputProps = Props & {
  error?: DeepMap<FieldValues, FieldError>;
  type?: string;
  'data-validate'?: string;
};

export const DefaultForm: React.FC<InputProps> =(props)=>{
let className = undefined;
if(props.error){
	className = 'error';
}
return(
	<>
	<InputFormStyled className = {className} {...props}/>
	</>
)
}

const Flex = styled.div`

`
const InputFormStyled = styled(Input)`
height: 36px;
width: 303px;
left: 0px;
top: 0px;
border-radius: 4px;
&.error{
	border-color: red;
}
`