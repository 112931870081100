import React, { useRef, useState ,useMemo, useEffect} from 'react';
import { Space } from 'antd'
import { HeaderLabel } from '@components/form/HeaderLabel'
import { DefaultButton } from '@components/button/DefaultButton'
import { Flex } from '@components/flex/flex'
import { RegisterForm } from './RegisterForm';
import { Gear } from './Gear'
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import { useNotification } from '@hooks/notification';
import { DefaultModal } from '@components/modal/DefaultModal'
import { yupResolver } from '@hookform/resolvers/yup';
import { RegisterLayout, StyleCol } from '@layouts/RegisterLayout'
import firebase from 'firebase';
import $axios from 'axios';
import * as yup from 'yup';
import { CheckCircleTwoTone } from '@ant-design/icons';
interface DefaultForm {
	Email: string | null,
	Mobilephone_No: number | null,
	Profile_URL: string | null,
	name: string | null,
	surname: string | null,
	otherTools: string,
	otherTypes: string,
	tools: string[],
	types: string[]
}
const defaultValue = {
	name: null,
	Email: null,
	Mobilephone_No: undefined,
	Profile_URL: null,
	surname: null,
	other: null,
	tools: null,
	types: null
}

export const Register: React.FC = (props) => {
	const compRefs = useRef<any>({});
	const [open] = useNotification()
	const [isModalVisible, setIsModalVisible] = useState(false);
	const baseURL = 'https://us-central1-znapplus-302216.cloudfunctions.net/sendMail?dest=';
	const axios = $axios.create({ baseURL });
	const [isOtherSelect, setIsOtherSelect] = useState(true);
	const schema = useMemo(()=>yup.object({
		name: yup.string().required(),
		Email: yup.string().required(),
		Mobilephone_No: yup.number().required(),
		Profile_URL: yup.string().nullable(),
		surname: yup.string().required(),
		other: yup.string().when('tools', {
			is: () => isOtherSelect === true,
			then: (s: yup.StringSchema) => s.required(),
			otherwise: (s: yup.StringSchema) => s.nullable(true),
		}) as yup.StringSchema,
		tools: yup.array(),
		types: yup.array(),
		province: yup.string().required(),
	}),[isOtherSelect]);
	
	const methods = useForm(
		{
			mode: 'all',
			reValidateMode: 'onChange',
			defaultValues: defaultValue,
			resolver: yupResolver(schema)
		}
	);


	const saveAll = () => {
		const data = methods.getValues()
		var emailcheck = data.Email;
		return firebase.database().ref('/users')
			.orderByChild("Email")
			.equalTo(emailcheck)
			.once('value', function (snapshot) {
				if (snapshot.val()) {
					return open({ type: 'warning', message: 'Email Is exist' });

				}
				else if (snapshot.val() == null) {
					const ref = firebase.database().ref('/users');
					var newPostRef = ref.push();
					(newPostRef.set({
						...data
					}, error => {
						if (error) {
							open({ type: 'error', message: 'Something went wrong' });
						} else {
							checkMail()
							return console.log('end')
						}
					}))

				}
			});

	}
	useEffect(() => {
		const listItem = methods.getValues('tool') as Array<string>;
		if (listItem && listItem.includes('Others')) {
			setIsOtherSelect(true)
		} else {
			setIsOtherSelect(false)
		}
	}, [methods.watch('tool')]);
	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const registerRef = (name: string) => (el: any): void => {
		compRefs.current[name] = el;
	};
	const checkMail = () => {
		if (methods.getValues('Email')) {
			axios.post('https://us-central1-znapplus-302216.cloudfunctions.net/sendMail?dest=' +
				`${methods.getValues('Email')}` +
				`&&fristname=${methods.getValues('name')}` +
				`&&lastname=${methods.getValues('surname')}`).then(() => {
					setIsModalVisible(true);
					methods.reset(defaultValue)
				});
		} else {
			open({ type: 'warning', message: 'กรุณาใส่อีเมล' });
		}
	}
	return (
		<>
			<RegisterLayout>
				<div style={{ background: '#ffffff', paddingBottom: '20px' }}>
					<StyledHeaderLabel>
						ลงทะเบียนเป็นตากล้องกับ Znap+
			</StyledHeaderLabel>
					<FormProvider {...methods} >
						<RegisterForm ref={registerRef('RegisterForm')} />
						<Gear ref={registerRef('Gear')} />
					</FormProvider>
				</div>
				<div style={{ paddingTop: '20px' }}>
					<Flex justifyContent={'flex-end'} padding-right={'732px'}>
						<Space size={17}>
							<DefaultButton className={'secondary'}>ยกเลิก</DefaultButton>
							<DefaultButton onClick={methods.handleSubmit(saveAll)}>ลงทะเบียน</DefaultButton>
						</Space>
					</Flex>
				</div>
			</RegisterLayout>
			<StyledModal
				visible={isModalVisible}
				onCancel={handleCancel}
				onOk={handleCancel}
			>
				<Space direction={'vertical'} align={'center'} style={{ width: '100%' }} size={20}>
					<SuccessMessage>
						ลงทะเบียนสำเร็จ
					</SuccessMessage>
					<CheckCircleTwoTone style={{ fontSize: '1000%' }} twoToneColor="#52c41a" />
				</Space>
			</StyledModal>
		</>
	);
}


const StyledHeaderLabel = styled(HeaderLabel)`
	padding-top:20px;
`
const SuccessMessage = styled.div`
font-size:20px;
`
const StyledModal = styled(DefaultModal)`
@media (max-width: 700px) {
	width:300px !important;
height:200px !important;
}
`