import React from 'react';
// import logo from './logo.svg';
import "antd/dist/antd.css";
import {Register} from 'pages/Register/Register'
import { Switch, Route, BrowserRouter } from 'react-router-dom';


import { Pages } from 'pages/Pages.page'

import './App.css';
import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyB1xAWaFFKtuGGGrBgL3dmqvyFNzxPdRZc",
  authDomain: "znapplus-302216.firebaseapp.com",
  databaseURL: "https://znapplus-302216-default-rtdb.firebaseio.com",
  projectId: "znapplus-302216",
  storageBucket: "znapplus-302216.appspot.com",
  messagingSenderId: "645857089091",
  appId: "1:645857089091:web:eee50985343bbda87666f6",
  measurementId: "G-KZ10LWL79W"
};
firebase.initializeApp(firebaseConfig);

function App() {
  return (
      <BrowserRouter>
       <div className='App'>
        <Switch>
          <Route path='/' component={Pages} />
        </Switch>
        </div>
      </BrowserRouter>
   
  );
}

export default App;
