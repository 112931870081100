import React from 'react'
import { notification } from 'antd';
import { ExclamationCircleOutlined, CloseCircleFilled, SaveOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface NotificationProps {
	type?: 'success' | 'error' | 'info' | 'warning';
	message: string;
}

export const useNotification = () => {
	const open = (args: NotificationProps) => {
		const type = args.type || 'success';
		let icon = undefined;
		if (type === 'success') { icon = <SaveOutlined className={type} /> }
		if (type === 'error') { icon = <CloseCircleFilled className={type} /> }
		if (type === 'warning') { icon = <ExclamationCircleOutlined className={type} /> }
		notification.config({
			duration: 2.5
		})
		notification.open({
			message: (
				<span style={{ fontWeight: 'bold' }}>{args.message}</span>
			),
			description: type === 'success' ? 'การบันทึกเสร็จสิ้น' : 'เกิดข้อผิดพลาด',
			icon: <StyledNotification >{icon}	</StyledNotification>
		})
	};
	return [open];
}
const StyledNotification = styled.div`
.warning {
	color:red;
}
.error{
	color:red;
}
.success {
	color:green;
}
`