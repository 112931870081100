import React from 'react';
import { Checkbox, Row, Col, Input, } from 'antd';
import styled from 'styled-components'
import { CheckboxChangeEvent, CheckboxProps as Props } from 'antd/lib/checkbox';
import { Control, Controller, DeepMap, FieldValues, FieldError } from 'react-hook-form'
import { Flex } from '@components/flex/flex'
import { DefaultForm } from '@components/form/DefaultForm'
export type CheckboxInterface = Omit<Props, 'onChange'> & {
	items: any;
	required?: boolean;
	disabled?: boolean;
	onOtherSelect?: boolean;
	otherName?:string
	onChange?: (e: any) => void;
	onChangeAddOther?: (e: any) => void;
	control?: Control<Record<string, any>>
	controlError: DeepMap<FieldValues, FieldError>
}
export const CheckboxForm: React.FC<CheckboxInterface> = ({
	items,
	required,
	onChange,
	onChangeAddOther,
	disabled,
	...props
}) => {
	return (<>
		<CheckboxStyled
			{...props}
			// options={items}
			disabled={disabled}
			defaultValue={undefined}
			onChange={onChange}
			style={{ width: '100%' }}
		>
			<Row >
				{
					items.map((item: any) => {
						return (
							<>
								<StyledColWidth key={item.value}>
									<StyledFlex flexGrow={1}>
										<Checkbox value={item.value}>{item.label}</Checkbox>
										{item.value === 'Others' && props.onOtherSelect && (
											<Col >
												<Controller
													name={props.otherName? props.otherName:''}
													defaultValue={null}
													control={props.control}
													render={({ onChange }) => {
														return <>
															<DefaultForm
																error={props.controlError[`${props.otherName}`]}
																onChange={onChange}
																placeholder={'กรุณากรอกตัวเลือก'} /></>
													}}
												/>
											</Col>
										)}
									</StyledFlex>
								</StyledColWidth>
							</>
						)
					})

				}
			</Row>
		</CheckboxStyled>

	</>)
}

const CheckboxStyled = styled(Checkbox.Group)`
	display: flex;
	flex-wrap: wrap;
	
	.ant-checkbox-group-item {
		flex: 0 0 28%;
		text-align:start;
		line-height: 23px;
		margin-left:10px;
		@media (max-width: 700px) {
			flex: 0 0 40% !important;
		}
	}
	.ant-checkbox + span {
		font-family: Prompt;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
	}
	.ant-checkbox-checked .ant-checkbox-inner{
		background-color: #44567A;
		border-color: #44567A;
	}
	.ant-checkbox-checked::after {
		outline: none;
		box-shadow: none;
	}
	.ant-checkbox-wrapper{
		display:flex;
		align-items:center;
	}
	.ant-row{
		margin: 10px 0 0 10px;
	}
	`
const StyledInput = styled(Input)`
height: 36px;
width:303px;
border-radius: 4px;
`
const StyledColWidth = styled.div`
position: relative;
max-width: 100%;
min-height: 1px;
flex: 0 0 28%;
		text-align:start;
		line-height: 23px;
		margin-left:10px;
		@media (max-width: 700px) {
			flex: 0 0 40% !important;
		}
`
const StyledFlex = styled(Flex)`
flex-flow: row;
@media (max-width: 700px) {
	flex-flow: column !important;
}

`