import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import styled from 'styled-components';
import { InputNumber } from 'antd';

export type InputNumberFormProps = NumberFormatProps & {
	style?: React.CSSProperties;
	disabledType?: 'dim';
	error?: FieldError;
};
const InputNumberForm: React.FC<InputNumberFormProps> = ({ style, className, disabledType, ...props }) => {
	const classNames = useMemo(() => {
		const names = [];
		if (className) {
			names.push(className);
		}
		if (disabledType) {
			names.push(`disabled-${disabledType}`);
		}
		if (props.error) {
			names.push('error');
		}
		return names.join(' ');
	}, [className, disabledType, props.error]);
	return <InputNumberFormat {...props} className={classNames} style={{ ...style }} />;
};
const InputNumberFormat = styled(NumberFormat)`
	height: 36px;
	width: 303px;
	left: 0px;
	top: 0px;
	border-width: thin;
	border: 1px solid #d9d9d9;
	border-radius: 4px;

	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color:rgba(0, 0, 0, 0.3);
	}
	&.error{
		border-color: red;
	}
	&:focus {
		border-color: #40a9ff;
		box-shadow: 0 0 3px #40a9ff;
    outline-offset: 0px;
    outline: none;
  }
	&:hover {
		border-color: #40a9ff;
  }
color:black;
text-indent: 10px;


`;

export default InputNumberForm;
